import React from "react";
import { motion } from "framer-motion";
import pilllogo from "./pilllogo.svg";
import temlebig from "./static/templebig.png";
import "./App.css";

function App() {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1 } },
  };

  return (
    <div className="App">
      <header className="base">
        {/* <motion.div
          className="logoWrapper"
          initial="hidden"
          animate="visible"
          variants={containerVariants}
        >
          <img src={pilllogo} className="App-logo" alt="logo" />
        </motion.div> */}
        <div className="main">
          <motion.div
            className="content-container"
            initial="hidden"
            animate="visible"
            variants={containerVariants}
          >
            <img src={pilllogo} className="App-logo" alt="logo" />

            <h1 className="title">Placebo Church</h1>
            <p className="subtitle">A new religion for a new reality</p>
          </motion.div>
          <motion.img
            src={temlebig}
            className="temple"
            alt="church"
            initial="hidden"
            animate="visible"
            variants={containerVariants}
          />
        </div>
      </header>
    </div>
  );
}

export default App;
